import { pullFrontToFirst } from "../Detail/Gallery"

export const convertWatch = (multilang) => (watch) => {
  return {
    id: watch.id,
    image:
      watch.resources && watch.resources.length
        ? pullFrontToFirst(watch.resources)[0].uri
        : null,
    title: `${multilang(watch.model, "name")} ${watch.refNo?.refNo || ""}`,
    brand: {
      name: multilang(watch.brand, "name"),
    },
    price: watch.amount,
    currency: watch.currency,
    seller: watch.details?.seller,
    reserved: watch.details?.reserveStatus == "RESERVED",
    brandId: watch.brandId,
  }
}
