import SliderUnstyled from "@mui/base/SliderUnstyled"
import { classNames } from "@/services/utils"

const Slider = function (props) {
  const { sliderStyle, ...rest } = props
  const className = classNames("my-slider", sliderStyle)
  return <SliderUnstyled className={className} {...rest} />
}

export default Slider
