import Head from "next/head"

export default function Description({ content }) {
  return (
    <Head>
      <meta name="description" content={content} />
      <meta property="og:description" content={content} />
    </Head>
  )
}
