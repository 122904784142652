import { BaseSelect } from "@/components/common/Form"

const Select = ({ children, onChange, className }) => {
  return (
    <div className="relative">
      <select
        className={`block focus:outline-none border rounded-none border-gray-800 focus:border-ochre-200 transition-colors py-[11px] px-4 bg-white appearance-none ${className}`}
        onChange={onChange}
      >
        {children}
      </select>
      <div className="absolute top-5 right-2 pointer-events-none">
        <img src="/icon/arrow-down.svg" alt="" />
      </div>
    </div>
  )
}

export default Select

export function SearchSelect(props) {
  return (
    <BaseSelect
      {...props}
      customStyles={{
        control: (provided, { isFocused }) => {
          return {
            ...provided,
            border: isFocused ? "1px solid #C8A56C" : "1px solid #939393",
            padding: "5px 8px",
            borderRadius: "None",
            boxShadow: "None",
            minHeight: "None",

            ":hover": {
              border: "1px solid #939393",
            },
          }
        },
        container: (provided) => {
          return {
            ...provided,
            paddingTop: "0px",
            paddingBottom: "0px",
            backgroundColor: "transparent",
          }
        },
      }}
    />
  )
}
