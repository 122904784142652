import { DateTime } from "luxon"
import { formatter } from "@/hooks/useCurrency"

export const formatedDate = (value, locale = "en") => {
  const date = DateTime.fromISO(value)
  let displayDate = date.toFormat("LLL d, yyyy")
  if (locale.match("zh")) displayDate = date.toFormat("yyyy 年 L 月 d 日")
  return displayDate
}

export const formatedDatetime = (value, locale = "en") => {
  const date = DateTime.fromISO(value)
  let displayDate = date.toFormat("LLL d, yyyy")
  if (locale.match("zh")) displayDate = date.toFormat("yyyy 年 L 月 d 日")
  return `${displayDate} ${date.toFormat("HH:mm")}`
}

export const formatPriceRange = (from, to, currency, t) => {
  const lowerBound = formatter.format(from || 0)
  const upperBound = formatter.format(to || 0)

  let result = ""
  if (upperBound === "0") {
    result = t("commission.above", {
      amount: `${currency} ${lowerBound}`,
    })
  } else if (lowerBound === "0") {
    result = t("commission.below", {
      amount: `${currency} ${upperBound}`,
    })
  } else {
    result = `${currency} ${lowerBound} ~ ${upperBound}`
  }
  return result
}

export const formatPriceRangeForCommission = (from, to) => {
  const lowerBound = formatter.format(from || 0)
  const upperBound = formatter.format(to || 0)

  let result = ""
  if (upperBound === "0") {
    result = `≥ $${lowerBound}`
  } else if (lowerBound === "0") {
    result = `≤ $${upperBound}`
  } else {
    result = `$${lowerBound} - $${upperBound}`
  }
  return result
}

export const formatedNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-US")
  return formatter.format(number)
}
