import { useMultilang } from "@/hooks/useMultilang"
import Description from "./Description"
import Title from "./Title"

export default function Meta({ path, title, description }) {
  const { t } = useMultilang()
  console.log(path, t(`meta.desc.${path}`))

  return (
    <div>
      <Title title={title || t(`meta.title.${path}`)} />
      <Description content={description || t(`meta.desc.${path}`)} />
    </div>
  )
}
