import { useMemo, useState } from "react"
import { useMultilang } from "@/hooks/useMultilang"
import { useRouter } from "next/router"

const MobileMenu = ({ children, defaultQuery }) => {
  const [show, setShow] = useState(false)
  const router = useRouter()
  const { t } = useMultilang()
  const menuClass = useMemo(
    () => (show ? "translate-x-0 overflow-scroll z-30" : "translate-x-full "),
    [show]
  )

  function toggle() {
    setShow(!show)
  }

  function clear() {
    router.push({
      pathname: router.pathname,
      query: defaultQuery,
    })
    setShow(false)
  }
  return (
    <div>
      <div className="block tablet:hidden mb-2 float-right ">
        <div
          className="border border-gray-700 text-center w-11 h-11 py-3"
          onClick={toggle}
        >
          <img src="/icon/filter.svg" alt="" className="w-full h-full" />
        </div>
      </div>
      <div className="clear-right"></div>
      <div
        className={`fixed top-0 left-0 h-full w-full p-8 pb-0 bg-white transition-transform tablet:translate-x-0 tablet:relative tablet:p-0  ${menuClass}`}
      >
        <div className="block tablet:hidden text-center mb-5 relative">
          <h5 className="font-bold uppercase text-lg">
            {t("search.mobile.filter")}
          </h5>
          <div className="absolute right-2 top-1">
            <img src="/icon/cancel.svg" alt="" onClick={toggle} />
          </div>
        </div>
        {children}
        <div className="tablet:hidden sticky bottom-0 left-0 right-0 bg-white py-5 text-center z-40">
          <button
            type="button"
            className="border-button normal-button-padding m-2 w-[125px]"
            onClick={clear}
          >
            {t("btn.clear")}
          </button>
          <button
            type="button"
            className="uppercase bg-black-300 text-white normal-button-padding m-2 w-[125px]"
            onClick={toggle}
          >
            {t("btn.apply")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
