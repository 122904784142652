import { forwardRef, useImperativeHandle, useState } from "react"

const Dropdown = forwardRef(
  (
    {
      defaultCollapse,
      disabled = false,
      children,
      label,
      listHeight,
      nonScrollable,
      overflow,
      onToggle,
    },
    ref
  ) => {
    const [collapse, setCollapse] = useState(defaultCollapse || false)
    function onClick() {
      toggleCollapse(!collapse)
    }
    function toggleCollapse(open) {
      if (disabled) return
      setCollapse(open)
      if (onToggle) {
        onToggle(!open)
      }
    }

    useImperativeHandle(ref, () => ({
      toggleCollapse,
    }))
    const overflowClass =
      overflow || (nonScrollable ? "overflow-y-hidden" : "overflow-y-auto")
    return (
      <div>
        <div className="flex items-center" onClick={onClick}>
          <div className="grow">{label}</div>
          <div
            className={`${collapse ? "" : "rotate-180"} ${
              disabled && "cursor-not-allowed"
            } transition cursor-pointer`}
          >
            <img src="/icon/arrow-down.svg" alt="" />
          </div>
        </div>

        <div
          className={`${
            collapse ? "h-0" : listHeight || "h-36"
          } ${overflowClass} transition-height `}
        >
          {children}
        </div>
      </div>
    )
  }
)

Dropdown.displayName = "DropDown"

export default Dropdown
