const Checkbox = function ({ name = "", label, className = "", ...rest }) {
  return (
    <div className={`search-checkbox base-checkbox ${className}`}>
      <label className="flex items-starts">
        <input type="checkbox" className="invisible" name={name} {...rest} />
        <span className="mr-2 min-w-[15px] mt-[2px]"></span>
        {label}
      </label>
    </div>
  )
}

export default Checkbox
