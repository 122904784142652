const Input = ({
  type,
  placeholder,
  onChange,
  className,
  paddingX,
  ...rest
}) => {
  return (
    <input
      {...rest}
      className={`border border-gray-800 focus:border-ochre-200 transition-colors py-[11px] px-${
        paddingX || "4"
      } placeholder:text-gray-200 ${className}`}
      type={type}
      placeholder={placeholder || ""}
      onChange={onChange}
    />
  )
}

export default Input
