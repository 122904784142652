import SearchFilter from "./Filter"
import MobileMenu from "../MobileMenu"

const Filter = ({ defaultQuery, ...rest }) => {
  return (
    <MobileMenu>
      <SearchFilter defaultQuery={defaultQuery} {...rest}></SearchFilter>
    </MobileMenu>
  )
}

export default Filter
