const { useState, useMemo } = require("react")

const usePrefixFilter = (data, fields) => {
  const [prefix, setPrefix] = useState("")

  const results = useMemo(() => {
    return data.filter((d) => {
      for (const field of fields) {
        if (d[field] && d[field].toUpperCase().startsWith(prefix.toUpperCase()))
          return true
      }
      return false
    })
  }, [prefix, fields, data])

  return { setPrefix, prefix, results }
}

export default usePrefixFilter
