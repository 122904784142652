import { useRouter } from "next/router"
const { useEffect, useState } = require("react")
import merge from "lodash/merge"
import Debug from "debug"

const debug = Debug("web:hook:useQueryValue")

const FILTER_QUERY = [
  "brandId",
  "modelId",
  "prices",
  "year",
  "color",
  "material",
  "sizes",
  "gender",
  "condition",
  "packing",
  "availability",
  "keyword",
  "sort",
  "reserveStatus",
  "currency",
  // 'id', // seller/:id
]

const useQueryValue = () => {
  const router = useRouter()
  const query = router.query
  const [queryValue, setQueryValue] = useState({})

  useEffect(() => {
    function updateQueryValue() {
      const payload = {}
      for (const k of FILTER_QUERY) {
        if (query[k]) {
          if (k === "prices") {
            payload[k] = decodeURIComponent(query[k]).split("~")
          } else {
            payload[k] = decodeURIComponent(query[k])
              .split(",")
              .filter((v) => v)
          }
        }
      }
      setQueryValue(payload)
    }
    updateQueryValue()
  }, [query])

  function setQuery(params, defaultQuery) {
    debug(params, defaultQuery)
    const payload = {}
    for (const k in params) {
      if (!params[k] || !params[k].length) continue
      if (Array.isArray(params[k])) {
        payload[k] = encodeURIComponent(params[k].join(","))
      } else if (k !== "keyword") {
        payload[k] = encodeURIComponent(params[k])
      } else {
        payload[k] = params[k]
      }
    }
    // console.log(params, payload, queryValue)
    router.push(
      {
        pathname: router.pathname,
        query: merge(payload, defaultQuery),
      },
      undefined,
      { shallow: true }
    )
  }

  return { queryValue, setQuery, FILTER_QUERY }
}

export default useQueryValue
