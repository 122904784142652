import { forwardRef, useImperativeHandle, useState } from "react"

const Autocomplete = forwardRef(
  ({ list, onClick, query, seperatorIndex, onSelectedMove }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(null)

    const regex = new RegExp(`(${query})`, "ig")

    function highlight(l) {
      try {
        return l.replace(regex, '<span class="font-normal">$1</span>')
      } catch (error) {
        return l
      }
    }

    function updateSelectedIndex(index) {
      setSelectedIndex(index)
    }
    function moveSelectedIndex(direction) {
      let index
      if (!list.length) return
      if (direction == "up") {
        if (selectedIndex === null) {
          index = list.length - 1
        } else if (selectedIndex === 0) {
          index = null
        } else {
          index = selectedIndex - 1
        }
      } else if (direction == "down") {
        if (selectedIndex === null) {
          index = 0
        } else if (selectedIndex + 1 === list.length) {
          index = null
        } else {
          index = selectedIndex + 1
        }
      }
      updateSelectedIndex(index)
      if (index === null) {
        onSelectedMove && onSelectedMove("")
      } else {
        onSelectedMove && onSelectedMove(list[index])
      }
    }

    useImperativeHandle(ref, () => ({
      moveSelectedIndex,
    }))

    return (
      <div
        className={`border absolute w-full z-40 bg-white ${
          list.length ? "" : "hidden"
        }`}
      >
        {list.map((l, i) => (
          <div
            key={l}
            onClick={() => onClick(l)}
            onMouseOver={() => setSelectedIndex(i)}
            className={`px-3 py-[10px] desktop:px-6 cursor-pointer font-bold border-t ${
              seperatorIndex == i ? "border-gray-300" : "border-gray-100"
            } ${selectedIndex == i ? "bg-gray-100" : ""}`}
            dangerouslySetInnerHTML={{ __html: highlight(l) }}
          ></div>
        ))}
      </div>
    )
  }
)

Autocomplete.displayName = "Autocomplete"

export default Autocomplete
