import { useRef, useState } from "react"
import { useRouter } from "next/router"
import axios from "@/services/axios"
import Autocomplete from "./Autocomplete"
import { useClickOutside } from "@/hooks/useClickOutside"
import { useMultilang } from "@/hooks/useMultilang"
import find from "lodash/find"

const SearchBar = ({ inputStyle, buttonStyle, onSubmit }) => {
  const { multilang, t } = useMultilang()
  const router = useRouter()
  const [autocompletes, setAutoCompletes] = useState([])
  const [seperatorIndex, setSeperatorIndex] = useState(null)
  const [selected, setSelected] = useState("")
  const autocompleteContainerRef = useRef()
  const autocompleteRef = useRef()
  let defaultQuery = ""
  if (router.query && router.query.keyword) {
    defaultQuery = decodeURIComponent(router.query.keyword)
  }
  const [query, setQuery] = useState(defaultQuery)
  useClickOutside(autocompleteContainerRef, () => setAutoCompletes([]))

  function onChange(e) {
    const val = e.target.value
    setQuery(val)
    setSelected("")
    triggerAutocomplete(val, e.target)
    e.preventDefault()
  }

  function onKeyDown(e) {
    if (e.key == "Enter") {
      setAutoCompletes([])
    } else if (e.key === "ArrowDown") {
      autocompleteRef.current.moveSelectedIndex("down")
    } else if (e.key === "ArrowUp") {
      autocompleteRef.current.moveSelectedIndex("up")
    }
  }

  function parseResults(res) {
    const { brands, models, refNos, target } = res.data
    const brandNames = brands
      .filter((b) => b.match === true)
      .map((b) => multilang(b, "brandName"))
    const modelNames = models
      .filter((m) => m.match === true)
      .map((m) => {
        let brand = find(brands, { id: m.brandId })
        let brandName = ""
        if (brand) {
          brandName = multilang(brand, "brandName")
        }
        return [brandName, multilang(m, "modelName")].filter((m) => m).join(" ")
      })
    const refNoNames = refNos
      .filter((r) => r.match === true)
      .map((r) => {
        let brand = find(brands, { id: r.brandId })
        let brandName = ""
        if (brand) {
          brandName = multilang(brand, "brandName")
        }
        let model = find(models, { id: r.modelId })
        let modelName = ""
        if (model) {
          modelName = multilang(model, "modelName")
        }
        return [brandName, modelName, r.refNo].filter((m) => m).join(" ")
      })

    let results = []
    if (target == "brand") {
      results = [...brandNames, ...modelNames]
      setSeperatorIndex(modelNames.length ? brandNames.length : null)
    } else if (target == "model") {
      results = [...modelNames, ...refNoNames]
      setSeperatorIndex(null)
    } else if (target == "refNo") {
      results = refNoNames
      setSeperatorIndex(null)
    }
    return results
  }

  async function fetchAutoComplete(q, inputRef) {
    try {
      const res = await axios.get(`/api/autocomplete/brand_model_refnos?q=${q}`)
      let results = parseResults(res)
      // console.log(results)

      if (inputRef.value === q) {
        setAutoCompletes([...new Set(results.filter((r) => r))])
      }
    } catch (error) {
      console.error(error)
    }
  }

  function triggerAutocomplete(val, inputRef) {
    if (val) {
      fetchAutoComplete(val, inputRef)
    } else {
      setAutoCompletes([])
    }
  }

  function updateRoute(q) {
    onSubmit(q)
    // setQueryValue({ keyword: q })
    setQuery(q)
    setAutoCompletes([])
  }

  function submit(e) {
    onSubmit(query)
    // setQueryValue({ keyword: query })
    e.preventDefault()
  }

  return (
    <div className="relative">
      <form className="flex" onSubmit={submit}>
        <input
          style={inputStyle}
          className={`grow inline-flex items-center rounded-none appearance-none px-3 py-[10px] desktop:px-6 text-gray-900 bg-gray-100 border border-r-0 border-gray-100 focus:outline-none`}
          value={selected || query}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type="search"
          placeholder={t("search.placeholder")}
        />
        <button
          type="submit"
          style={buttonStyle}
          className="inline-flex items-center px-3 py-[10px] text-sm text-gray-900 bg-gray-100 border border-l-0 border-gray-100"
        >
          <img src="/icon/search.svg" width={24} height={24} alt="search" />
        </button>
      </form>
      <div ref={autocompleteContainerRef}>
        <Autocomplete
          ref={autocompleteRef}
          list={autocompletes}
          seperatorIndex={seperatorIndex}
          onClick={updateRoute}
          onSelectedMove={setSelected}
          query={query}
        />
      </div>
    </div>
  )
}

export default SearchBar
