import customAxios from "@/services/axios"
import axios from "axios"
import useSWR from "swr"
import Debug from "debug"

const debug = Debug("web:service:watch")

const BRAND_END_POINT = `https://${process.env.NEXT_PUBLIC_OSS_DOMAIN}/public-access/brand-list/brands.json`
const MODEL_END_POINT = `https://${process.env.NEXT_PUBLIC_OSS_DOMAIN}/public-access/brand-list/models.json`
const getWatchResourceEndpoint = (id) => `/api/watches/me/${id}/resource`

export const search = async function (query) {
  const res = await customAxios
    .get("/api/watches", { params: query })
    .catch(() => ({
      results: [],
      numResults: 0,
      availFilters: {},
    }))
  debug(res.data)

  return res.data
}

export const useBrands = () => {
  const { data: res, error, isValidating } = useSWR(BRAND_END_POINT, axios.get)

  const brandData = isValidating ? [] : res.data
  return [brandData, error]
}

export const useModels = () => {
  const { data: res, errors, isValidating } = useSWR(MODEL_END_POINT, axios.get)
  const modelData = isValidating ? [] : res.data

  return [modelData, errors]
}

export const getSellWatchCommissionRate = async () => {
  const commissionRate = 0.055

  return commissionRate
}

export const createMyWatch = async (payload) => {
  const endPoint = "/api/watches/me"
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const getSeller = async ({ id }) => {
  const res = await customAxios.get(`/api/users/seller/${id}`)

  return res.data
}

export const useWatchMainPage = () => {
  const endPoint = "/api/contents/WATCH_MAIN_PAGE"
  const { data: res, errors, isValidating } = useSWR(endPoint, customAxios.get)
  const data = isValidating ? {} : res.data

  return [data, errors]
}

export const getWatchMainPage = async () => {
  const endPoint = "/api/contents/WATCH_MAIN_PAGE"
  const res = await customAxios.get(endPoint)

  return res.data
}

export const getMyWatch = async (id) => {
  const endPoint = `/api/watches/me/${id}`
  const res = await customAxios.get(endPoint)

  return res.data
}

export const updateMyWatch = async (payload, id) => {
  const endPoint = `/api/watches/me/${id}`
  const res = await customAxios.put(endPoint, payload)

  return res.data
}

export const deleteWatchResource = async (payload, id) => {
  const endPoint = getWatchResourceEndpoint(id)
  const res = await customAxios.delete(endPoint, { data: payload })

  return res.data
}

export const createWatchResource = async (payload, id) => {
  const endPoint = getWatchResourceEndpoint(id)
  const res = await customAxios.post(endPoint, payload)

  return res.data
}

export const getWatchDetail = async function (id) {
  const endPoint = `/api/watches/${id}`
  const res = await customAxios.get(endPoint)

  return res.data
}
