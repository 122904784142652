import Head from "next/head"

export default function Title({ title }) {
  const formatedTitle = `${title} | KRONOS8`
  return (
    <Head>
      <title>{formatedTitle}</title>
      <meta property="og:title" content={formatedTitle} />
    </Head>
  )
}
