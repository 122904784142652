import { useForm } from "react-hook-form"
import { useEffect, useMemo, useState } from "react"
import { Controller } from "react-hook-form"

import Dropdown from "@/components/common/DropDown"
import Checkbox from "./Checkbox"
import { SearchSelect } from "./Select"
import Input from "./Input"
import Slider from "@/components/common/Slider"
import colorJson from "@/assets/json/watchColor.json"
import conditionJson from "@/assets/json/watchCondition.json"
import materialJson from "@/assets/json/watchMaterial.json"
import watchPackingJson from "@/assets/json/watchPacking.json"
import genderJson from "@/assets/json/gender.json"
import yearJson from "@/assets/json/watchYear.json"
import statusJson from "@/assets/json/watchReserveStatus.json"
import { useBrands, useModels } from "@/services/watch"
import usePrefixFilter from "@/hooks/usePrefixFilter"
import useQueryValue from "@/hooks/useQueryValue"
import { useMultilang } from "@/hooks/useMultilang"
import { useCurrency } from "@/hooks/useCurrency"
import Debug from "debug"

const debug = Debug("web:component:Filter")

const DEFAULT_PRICES = ["", ""]

const SearchFilter = ({ defaultQuery, availableOptions }) => {
  const { multilang, t } = useMultilang()
  const { currency } = useCurrency()
  const { control, setValue, getValues } = useForm()
  const { queryValue, setQuery, FILTER_QUERY } = useQueryValue()
  const [prices, setPrices] = useState(DEFAULT_PRICES)
  const [sizes, setSizes] = useState(getDefaultSizes())

  const allBrands = useBrands()[0].filter((data) => data.id !== -1)
  const allModels = useModels()[0].filter((data) => data.id !== -1)

  function getAvailableSizes() {
    return availableOptions.caseDimWmm || { max: 60, min: 20 }
  }

  function getDefaultSizes() {
    const sizes = getAvailableSizes()
    return [sizes.min, sizes.max]
  }

  const options = useMemo(() => {
    function filterOption(list, listKey, available) {
      if (!available) return list
      return list.filter((l) => available.indexOf(l[listKey].toString()) !== -1)
    }

    function filterYearOption() {
      if (!availableOptions.prodYear) return yearJson
      return yearJson.filter((y) => {
        const [min, max] = y.value.split("-")
        if (max) {
          const maxInt = parseInt(max)
          const minInt = parseInt(min)
          for (const year of availableOptions.prodYear) {
            try {
              const yearInt = parseInt(year)
              if (maxInt >= yearInt && yearInt >= minInt) {
                debug("available", y.value, maxInt, yearInt, minInt)
                return true
              }
            } catch (error) {
              debug(error)
            }
          }
          return false
        } else {
          return availableOptions.prodYear.indexOf(y.value) !== -1
        }
      })
    }

    function filterSizesOption() {
      const caseDimWmm = getAvailableSizes()

      return [...Array(caseDimWmm.max - caseDimWmm.min).keys()]
        .map((i) => i + caseDimWmm.min)
        .map((s) => ({ value: s }))
    }

    const genderMapping = (availableOptions.gender || []).map((g) => {
      if (g === "male") return "M"
      if (g === "female") return "F"
    })

    return {
      brands: filterOption(allBrands, "id", availableOptions.brandIds),
      models: filterOption(allModels, "id", availableOptions.modelIds),
      prodYears: filterYearOption(),
      colors: filterOption(
        colorJson.common,
        "value",
        availableOptions.dialColor
      ),
      materials: filterOption(
        materialJson.common,
        "value",
        availableOptions.caseMaterial
      ),
      sizes: filterSizesOption(),
      genders: filterOption(genderJson, "value", genderMapping),
      conditions: filterOption(
        conditionJson,
        "value",
        availableOptions.condition
      ),
      deliveryScopes: filterOption(
        watchPackingJson,
        "value",
        availableOptions.deliveryScope
      ),
      available: filterOption(
        statusJson,
        "value",
        availableOptions.reserveStatus
      ),
    }
  }, [availableOptions, allBrands, allModels])

  const {
    results: filteredBrand,
    prefix: brandPrefix,
    setPrefix: setBrandPrefix,
  } = usePrefixFilter(options.brands, ["nameEN", "nameTC", "nameSC"])

  const {
    results: filteredModels,
    prefix: modelPrefix,
    setPrefix: setModelPrefix,
  } = usePrefixFilter(options.models, ["nameEN", "nameTC", "nameSC"])

  useEffect(() => {
    if (Object.keys(queryValue).length === 0) {
      setBrandPrefix("")
      setModelPrefix("")
    }
  }, [queryValue, setBrandPrefix, setModelPrefix])

  useEffect(() => {
    for (const k of FILTER_QUERY) {
      const fieldValue = k === "sort" ? queryValue[k]?.[0] : queryValue[k]

      setValue(k, fieldValue)
    }
    function parseSizes() {
      if (!queryValue.sizes) {
        setSizes(getDefaultSizes())
      } else {
        setSizes(queryValue.sizes.map((s) => parseInt(s)))
      }
    }
    function parsePrices() {
      if (!queryValue.prices) {
        setPrices([...DEFAULT_PRICES])
      } else {
        setPrices(queryValue.prices.map((s) => (s ? parseInt(s) : "")))
      }
    }
    parseSizes()
    parsePrices()
  }, [queryValue])

  function updateRoute() {
    const params = getValues()
    if (params["prices"]) {
      params["prices"] = params.prices.join("~")
      params.currency = currency
    }
    setQuery(params, defaultQuery || {})
  }

  function updateSizes() {
    setValue("sizes", sizes)
    updateRoute()
  }

  function updatePrices() {
    let payload = prices
    if (prices[0] && prices[1] && parseInt(prices[0]) > parseInt(prices[1])) {
      payload = [prices[1], prices[0]]
    }

    setValue("prices", payload)
    updateRoute()
  }

  function getIndex(list, val) {
    let valString = val
    if (typeof val === "number") {
      valString = val.toString()
    }
    for (const i in list) {
      if (list[i] == valString) return i
    }
    return -1
  }

  function defaultChecked(field, val) {
    const value = queryValue[field]
    if (!value) return false
    return getIndex(value, val) !== -1
  }

  function updateFieldList(field, l, val) {
    const list = queryValue[field] || []
    const index = getIndex(list, val)
    if (index === -1) {
      list.push(val)
    } else {
      list.splice(index, 1)
    }
    setValue(field, list)
    updateRoute()
  }
  const sortOptions = [
    { label: t("search.sort-newest"), value: "publishTime_desc" },
    { label: t("search.sort-price-to-high"), value: "amount_asc" },
    { label: t("search.sort-price-to-low"), value: "amount_desc" },
  ]

  return (
    <div className="relative">
      <div className="filter-section">
        <label className="filter-label mb-2">{t("search.filter.sort")}</label>
        <Controller
          name="sort"
          control={control}
          defaultValue={sortOptions[0].value}
          render={({ field }) => (
            <SearchSelect
              {...field}
              ref={null}
              options={sortOptions}
              label={t("search.filter.sort")}
              onChange={(data) => {
                field.onChange(data.value)
                updateRoute()
              }}
              value={sortOptions.find((opt) => opt.value === field.value)}
              isSearchable={false}
              fieldClassName="mb-0 text-sm"
            />
          )}
        />
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.brand")}
            </label>
          }
          listHeight="h-[194px]"
        >
          <div className="">
            <div className="">
              <Input
                className="w-full"
                type="text"
                placeholder="e.g. Rolex"
                value={brandPrefix}
                onChange={(e) => setBrandPrefix(e.target.value)}
              />
            </div>
            <div className="mt-4 text-black-400 text-sm max-h-[130px] overflow-y-scroll translate-3d">
              <Controller
                name="brandId"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  filteredBrand.map((v) => (
                    <Checkbox
                      key={v.id}
                      label={multilang(v, "name")}
                      className="mb-2"
                      {...field}
                      onChange={() =>
                        updateFieldList("brandId", field.value, v.id)
                      }
                      checked={defaultChecked("brandId", v.id)}
                      value={v.id}
                      ref={null}
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.model")}
            </label>
          }
          listHeight="h-[194px]"
        >
          <div className="">
            <div className="">
              <Input
                className="w-full"
                type="text"
                value={modelPrefix}
                placeholder="e.g. Daytona"
                onChange={(e) => setModelPrefix(e.target.value)}
              />
            </div>
            <div className="mt-4 text-black-400 text-sm max-h-[130px] overflow-y-auto translate-3d">
              <Controller
                name="modelId"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  filteredModels.map((v) => (
                    <Checkbox
                      key={v.id}
                      label={multilang(v, "name")}
                      className="mb-2"
                      {...field}
                      onChange={() =>
                        updateFieldList("modelId", field.value, v.id)
                      }
                      checked={defaultChecked("modelId", v.id)}
                      value={v.id}
                      ref={null}
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.price", { currency })}
            </label>
          }
          listHeight="h-26"
        >
          <div className="relative mt-2">
            <div className="flex items-center mb-1">
              <Input
                className="w-32 px-2"
                type="number"
                value={prices[0]}
                placeholder="0"
                min={0}
                onChange={(e) => setPrices([e.target.value, prices[1]])}
                paddingX={2}
              />
              <span className="inline-block p-2"> - </span>
              <Input
                className="w-32 px-2"
                type="number"
                value={prices[1]}
                placeholder="50,000"
                min={0}
                onChange={(e) => setPrices([prices[0], e.target.value])}
                paddingX={2}
              />
            </div>
            <button
              className="border-button normal-button-padding text-sm"
              onClick={updatePrices}
            >
              {t("btn.ok")}
            </button>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mt-2 mb-2">
              {t("search.filter.year")}
            </label>
          }
          listHeight="h-[138px]"
        >
          <div className=" mt-2">
            <div className="text-black-400 text-sm ">
              <Controller
                name="year"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.prodYears.map((v, i) => (
                    <Checkbox
                      key={i}
                      name="year"
                      className="mb-2"
                      checked={defaultChecked("year", v.value)}
                      onChange={() =>
                        updateFieldList("year", field.value, v.value)
                      }
                      label={multilang(v, "display")}
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.color")}
            </label>
          }
          listHeight="h-[138px]"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="color"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.colors.map((v) => (
                    <Checkbox
                      key={v.value}
                      className="mb-2"
                      label={multilang(v, "display")}
                      checked={defaultChecked("color", v.value)}
                      onChange={() =>
                        updateFieldList("color", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.material")}
            </label>
          }
          listHeight="h-[138px]"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="material"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.materials.map((v) => (
                    <Checkbox
                      key={v.value}
                      className="mb-2"
                      label={multilang(v, "display")}
                      checked={defaultChecked("material", v.value)}
                      onChange={() =>
                        updateFieldList("material", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.width")}
            </label>
          }
          listHeight="h-30"
        >
          <div className="">
            <div className="px-8 pt-8 pb-5 relative mb-2">
              <Controller
                name="sizes"
                defaultValue={[20, 60]}
                control={control}
                render={({ field }) => (
                  <Slider
                    {...field}
                    ref={null}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(v) => `${v}mm`}
                    value={sizes}
                    marks={options.sizes}
                    step={null}
                    min={20}
                    max={60}
                    onChange={(e, v) => setSizes(v)}
                  />
                )}
              ></Controller>

              <span className="absolute top-16 left-2 text-xs text-gray-300">
                20mm
              </span>
              <span className="absolute top-16 right-2 text-xs text-gray-300">
                60mm
              </span>
            </div>

            <button
              className="border-button normal-button-padding text-sm"
              onClick={updateSizes}
            >
              {t("btn.ok")}
            </button>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.gender")}
            </label>
          }
          listHeight="h-14"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="gender"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.genders.map((v, i) => (
                    <Checkbox
                      key={i}
                      className="mb-2"
                      label={multilang(v, "display")}
                      checked={defaultChecked("gender", v.value)}
                      onChange={() =>
                        updateFieldList("gender", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.condition")}
            </label>
          }
          listHeight="h-[138px]"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="condition"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.conditions.map((v, i) => (
                    <Checkbox
                      key={i}
                      className="mb-2"
                      label={multilang(v, "name")}
                      checked={defaultChecked("condition", v.value)}
                      onChange={() =>
                        updateFieldList("condition", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.packing")}
            </label>
          }
          listHeight="h-[138px]"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="packing"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.deliveryScopes.map((v, i) => (
                    <Checkbox
                      key={i}
                      className="mb-2"
                      label={multilang(v, "display")}
                      checked={defaultChecked("packing", v.value)}
                      onChange={() =>
                        updateFieldList("packing", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="filter-section">
        <Dropdown
          label={
            <label className="filter-label mb-2 mt-2">
              {t("search.filter.available")}
            </label>
          }
          listHeight="h-10"
        >
          <div className="mt-2">
            <div className="text-black-400 text-sm">
              <Controller
                name="reserveStatus"
                defaultValue={[]}
                control={control}
                render={({ field }) =>
                  options.available.map((v, i) => (
                    <Checkbox
                      key={i}
                      className="mb-2"
                      label={multilang(v, "display")}
                      checked={defaultChecked("reserveStatus", v.value)}
                      onChange={() =>
                        updateFieldList("reserveStatus", field.value, v.value)
                      }
                    ></Checkbox>
                  ))
                }
              ></Controller>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default SearchFilter
